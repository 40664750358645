const attachAddressPicker = ($) => {
  $(document).on('focus', '.js-nzpost', function () {
    const jsnzpostthis = $(this);

    jsnzpostthis.autocomplete({
      source(request, response) {
        $.ajax({
          url: '/nzpost/address-suggestion',
          dataType: 'json',
          data: { q: request.term },
          success(data) {
            response($.map(data.addresses, item => ({
              label: item.FullAddress,
              value: item.DPID,
            })));
          },
        });
      },
      minLength: 3,
      select(event, ui) {
        $.ajax({
          url: '/nzpost/address-details',
          dataType: 'json',
          data: { dpid: ui.item.value },
          success(data) {
            jsnzpostthis.val('');
            $('.js-numberAndStreetName').val(data.details[0].AddressLine1);
            $('.js-suburb').val(data.details[0].Suburb);
            $('.js-city').val(data.details[0].CityTown);
            $('.js-postcode').val(data.details[0].Postcode);
            $('.js-nzpostId').val(data.details[0].DPID);
          },
        });
        event.preventDefault();
      },
      create() {
        // added nope ui automatically changes to off but all of sudden stupid
        // google wants nope
        $(this).attr('autocomplete', 'nope');
      },
      focus: (event) => {
        event.preventDefault(); // or return false;
      },
    }).data('ui-autocomplete')._renderItem = function (ul, item) {
      const newText = String(item.label).replace(
        new RegExp(this.term, 'gi'),
        '<span class=\'ui-menu-item__term\'>$&</span>',
      );

      return $('<li></li>')
        .data('item.autocomplete', item)
        .append(`<div>${newText}</div>`)
        .appendTo(ul);
    };
  });
};

module.exports = attachAddressPicker;

//NZpost autocomplete block
if ($(document).find(".js-nzpost ").length) {
    $(document).on('keyup', ".js-numberAndStreetName, .js-suburb, .js-city, .js-postcode", function(e) {
        $(".js-nzpostId").val('');
    });

    $(document).on('focus', ".js-nzpost", function() {
    var jsnzpostthis = $(this);
    jsnzpostthis.autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/nzpost/address-suggestion",
                dataType: "json",
                data: {q: request.term},
                success: function (data) {
                    response($.map(data.addresses, function (item) {
                        return {
                            label: item.FullAddress,
                            value: item.DPID
                        };
                    }));
                }
            });
        },
        minLength: 3,
        select: function (event, ui) {
            $.ajax({
                url: "/nzpost/address-details",
                dataType: "json",
                data: {dpid: ui.item.value},
                success: function (data) {
                    jsnzpostthis.val("");
                    $(".js-numberAndStreetName").val(data.details[0].AddressLine1);
                    $(".js-suburb").val(data.details[0].Suburb);
                    $(".js-city").val(data.details[0].CityTown);
                    $(".js-postcode").val(data.details[0].Postcode);
                    $(".js-nzpostId").val(data.details[0].DPID);
                }
            });
            event.preventDefault();
        },
        create: function (event, ui) {
            // added nope ui automatically changes to off but all of sudden stupid google wants nope
            $(this).attr('autocomplete', 'nope');
        },
        focus: function(event, ui) {
            event.preventDefault(); // or return false;
        }
    }).data("ui-autocomplete")._renderItem = function (ul, item) {
        var newText = String(item.label).replace(
            new RegExp(this.term, "gi"),
            "<span class='ui-menu-item__term'>$&</span>");

        return $("<li></li>")
            .data("item.autocomplete", item)
            .append("<div>" + newText + "</div>")
            .appendTo(ul);

    };
    });


}
