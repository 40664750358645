"use strict";


function HeaderScroll($, header) {
// Hide header on scroll down
    var
        lastScrollTop = 0,
        delta = 5,
        $header = $(header),
        navbarHeight = $header.outerHeight(),
        $slimHeader = $header.clone();

    $(window).scroll(function(event){
        hasScrolled();
    });

    $slimHeader.addClass('header-scroll');

    $slimHeader.appendTo($header.parent());


    function hasScrolled() {
        var st = $(window).scrollTop();
        if (st < navbarHeight) {
            $slimHeader.removeClass('active');
        }
        if(Math.abs(lastScrollTop - st) <= delta)
            return;

        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            $slimHeader.removeClass('nav-down').addClass('header-scroll active nav-up');
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
                $slimHeader.removeClass('nav-up').addClass('nav-down');
            }
        }
        lastScrollTop = st;
    }

};

module.exports = HeaderScroll;
